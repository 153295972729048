function Logo() {
  return (
    <div className="logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1475.227"
      height="601.747"
      version="1.1"
      viewBox="0 0 1475.227 601.747"
      xmlSpace="preserve"
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 601.747)">
        <g
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          transform="scale(.1)"
        >
          <path d="M4238.78 1169.95c-70.97-25.9-146.36-38.93-226.3-38.93-133.03 0-237.8 27.03-314.07 81.09-76.36 53.97-114.49 128.97-114.49 225.24 0 10.37.49 20.33 1.37 29.96.9 9.61 2.19 19.61 3.96 30l125.1 727.98h596.33l-103.77-617.04c-1.8-7.41-3.17-14.81-4.02-22.17-.94-7.46-1.37-14.81-1.37-22.23 0-29.61 8.4-52.91 25.29-69.84 16.9-17.09 44.86-25.57 83.87-25.57 40.8 0 73.2 12.17 97.17 36.6 23.96 24.44 46.56 54.34 67.93 89.91l106.46 630.34h596.23l-178.3-1069.84h-575.06l26.66 157.6c-70.99-69.57-141.99-117.26-212.99-143.1"></path>
          <path d="M6061.11 2744.67l-266.17-1589.22h-596.33l266.23 1589.22h596.27"></path>
          <path d="M8138.63 1608.25c0-44.4 11.09-80.28 33.26-107.7 22.17-27.34 61.7-41.03 118.48-41.03 24.82 0 50.09 5.62 75.8 16.66 25.76 11.07 51.09 24.1 75.95 38.86l55.84 326.27c-19.53 20.71-39 35.47-58.43 44.4-19.63 8.86-44.44 13.3-74.63 13.3-28.4 0-56.41-4.77-83.87-14.4-27.44-9.66-51.93-25.5-73.2-47.77-21.33-22.17-38.17-51.8-50.57-88.73-12.46-37.03-18.63-83.63-18.63-139.86m105.13-432.74c-61.23-29.63-123.76-44.49-187.63-44.49-186.37 0-323.87 38.93-412.64 116.56-88.79 77.64-133.12 183.87-133.12 318.5 0 97.69 15.93 188.3 47.93 271.89 31.97 83.58 77.59 156.04 137.07 217.54 59.49 61.37 132.69 109.1 219.63 143.16 86.97 34.03 186.32 51.06 298.16 51.06 79.86 0 145.94-14.11 198.34-42.23 52.32-28.07 93.5-74.72 123.79-139.82l26.6 157.61h598.97l-180.94-1069.84h-567.09l23.96 139.83c-67.46-50.29-131.74-90.24-193.03-119.77"></path>
          <path d="M10100.6 2210.84c71 25.96 146.4 38.89 226.3 38.89 133.1 0 237.8-27.03 314-81.02 76.3-54.04 114.6-129.14 114.6-225.33 0-10.31-.5-20.31-1.4-29.94-1-9.63-2.3-19.57-4-29.9l-125.1-728.09h-596.3l103.9 617.13c1.7 7.27 3 14.73 3.9 22.13.9 7.4 1.4 14.73 1.4 22.17 0 29.63-8.5 52.93-25.3 69.96-16.9 16.97-44.9 25.57-83.9 25.57-40.85 0-73.22-12.27-97.18-36.7-23.97-24.44-46.56-54.34-67.87-89.87l-106.47-630.39h-596.32l178.33 1069.84h575.02l-26.66-157.61c71.04 69.53 142.05 117.26 213.05 143.16"></path>
          <path d="M7451.83 2192.97l-46.83-311.7h-522.5c-96.14 0-155.39-69.76-177.93-209.25-2.74-16.88-4.2-32.91-4.2-48.4 0-95.37 47.4-143.01 142.13-143.01h520.46l-48.3-326.33-677.65 2.13c-208.3 0-359.57 37.4-453.87 112.27-81.33 64.29-122.03 160.43-122.03 288.53 0 32.78 2.5 68.5 7.56 106.97 48.77 353.13 292.93 529.45 732.4 528.79h650.76"></path>
          <path d="M2833.39 539.852H2731.7v82.718h291.25v-82.718h-101.68V9.07h-87.88v530.782"></path>
          <path d="M3086.78 622.57h87.89v-262.8h108.56v262.8h87.9V9.07h-87.9v273.141h-108.56V9.071h-87.89V622.57"></path>
          <path d="M3457.79 622.57h261.95v-82.718h-174.06V359.77h151.65v-82.731h-151.65V96.949h174.06V9.07h-261.95v613.5"></path>
          <path d="M4069.54 331.332h42.22c71.52 0 72.39 42.219 72.39 103.398 0 57.731 0 105.122-68.94 105.122h-45.67zm-87.89 291.238h131.84c48.25 0 83.58-11.191 113.73-43.082 30.17-31.879 39.65-70.648 39.65-143.898 0-53.418-4.32-89.61-25-123.219-24.12-39.629-62.9-63.762-127.52-63.762h-44.81V9.07h-87.89v613.5"></path>
          <path d="M4430.65 348.57h49.98c61.17 0 70.65 34.461 70.65 95.641 0 62.039-10.34 95.641-74.96 95.641h-45.67zm-87.89 274h141.32c104.25 0 155.09-61.172 155.09-180.09 0-70.648-19.81-122.351-75.82-153.371L4658.13 9.07h-93.06l-81.86 261.942h-52.56V9.07h-87.89v613.5"></path>
          <path d="M4802.96 153.832c0-42.23 27.57-62.043 59.45-62.043 31.89 0 59.47 19.813 59.47 62.043v323.977c0 42.23-27.58 62.043-59.47 62.043-31.88 0-59.45-19.813-59.45-62.043zm-87.89 323.977c0 99.961 75.83 149.933 147.34 149.933 71.53 0 147.35-49.972 147.35-149.933V153.832c0-99.953-75.82-149.934-147.35-149.934-71.51 0-147.34 49.98-147.34 149.934v323.977"></path>
          <path d="M5268.16 9.07h-77.55l-124.95 613.5h93.07l69.8-428.25h1.71l70.66 428.25h93.07L5268.16 9.07"></path>
          <path d="M5464 622.57h261.95v-82.718h-174.06V359.77h151.65v-82.731h-151.65V96.949h174.06V9.07H5464v613.5"></path>
          <path d="M5796.4 622.57h84.45l132.7-369.648h1.72V622.57h87.89V9.07h-82.72L5886.03 377.86h-1.73V9.07h-87.9v613.5"></path>
          <path d="M6671.82 445.941h-87.9v19.809c0 40.5-17.22 74.102-62.02 74.102-48.27 0-59.47-38.77-59.47-79.27 0-47.391 4.32-63.762 49.98-81.863l67.21-26.707c77.55-29.301 92.2-78.41 92.2-168.02 0-103.402-37.92-180.094-151.65-180.094-85.31 0-149.93 62.903-149.93 145.622v32.742h87.89v-27.571c0-31.882 17.23-62.902 62.04-62.902 59.45 0 63.75 35.332 63.75 91.34 0 65.48-7.74 75.82-50.83 93.051l-62.9 25.859c-75.83 31.012-95.64 80.992-95.64 157.68 0 93.062 49.98 168.023 150.79 168.023 87.88 0 146.48-70.66 146.48-138.73v-43.071"></path>
          <path d="M7029.74 146.07c0-75.828-61.19-142.172-139.6-142.172-67.2 0-144.76 32.743-144.76 144.762v337.77c0 80.14 57.73 141.312 143.04 141.312 80.99 0 141.32-61.172 141.32-146.472v-34.481h-87.9v29.301c0 34.469-20.68 63.762-54.29 63.762-43.94 0-54.28-30.161-54.28-71.52V154.691c0-35.332 13.79-62.902 53.42-62.902 24.13 0 55.15 13.781 55.15 62.043v30.16h87.9V146.07"></path>
          <path d="M7196.19 348.57h49.98c61.18 0 70.66 34.461 70.66 95.641 0 62.039-10.35 95.641-74.96 95.641h-45.68zm-87.89 274h141.31c104.26 0 155.11-61.172 155.11-180.09 0-70.648-19.82-122.351-75.83-153.371L7423.67 9.07h-93.05l-81.86 261.942h-52.57V9.07h-87.89v613.5"></path>
          <path d="M7493.36 622.57h261.95v-82.718h-174.06V359.77h151.65v-82.731h-151.65V96.949h174.06V9.07h-261.95v613.5"></path>
          <path d="M7827.33 622.57h261.94v-82.718h-174.06V359.77h151.66v-82.731h-151.66V96.949h174.06V9.07h-261.94v613.5"></path>
          <path d="M8159.75 622.57h84.43l132.7-369.648h1.73V622.57h87.89V9.07h-82.72L8249.35 377.86h-1.71V9.07h-87.89v613.5"></path>
          <path d="M9029.99 146.07c0-75.828-61.19-142.172-139.59-142.172-67.21 0-144.76 32.743-144.76 144.762v337.77c0 80.14 57.72 141.312 143.03 141.312 81 0 141.32-61.172 141.32-146.472v-34.481h-87.9v29.301c0 34.469-20.68 63.762-54.28 63.762-43.95 0-54.28-30.161-54.28-71.52V154.691c0-35.332 13.78-62.902 53.42-62.902 24.13 0 55.14 13.781 55.14 62.043v30.16h87.9V146.07"></path>
          <path d="M9108.55 622.57h87.88v-262.8h108.58v262.8h87.89V9.07h-87.89v273.141h-108.58V9.071h-87.88V622.57"></path>
          <path d="M9563.41 153.832c0-42.23 27.57-62.043 59.45-62.043 31.89 0 59.46 19.813 59.46 62.043v323.977c0 42.23-27.57 62.043-59.46 62.043-31.88 0-59.45-19.813-59.45-62.043zm-87.89 323.977c0 99.961 75.83 149.933 147.34 149.933 71.52 0 147.35-49.972 147.35-149.933V153.832c0-99.953-75.83-149.934-147.35-149.934-71.51 0-147.34 49.98-147.34 149.934v323.977"></path>
          <path d="M9854.46 9.07h87.89v613.5h-87.89z"></path>
          <path d="M10310.8 146.07c0-75.828-61.2-142.172-139.6-142.172-67.2 0-144.8 32.743-144.8 144.762v337.77c0 80.14 57.8 141.312 143.1 141.312 81 0 141.3-61.172 141.3-146.472v-34.481h-87.9v29.301c0 34.469-20.7 63.762-54.3 63.762-43.9 0-54.3-30.161-54.3-71.52V154.691c0-35.332 13.8-62.902 53.4-62.902 24.2 0 55.2 13.781 55.2 62.043v30.16h87.9V146.07"></path>
          <path d="M10389.4 622.57h261.9v-82.718h-174.1V359.77h151.7v-82.731h-151.7V96.949h174.1V9.07h-261.9v613.5"></path>
          <path d="M4268.08 3329.9H3160.35L1609.43 1088.21l-194.65 1137.08H307.473L847.457 0H1922.22l2345.86 3329.9"></path>
          <path d="M11064.2 3658.18c-623.7-645.22-1411.84-998.99-2317.76-960.14-1522.1 65.16-2931.23 785.91-4387.33 1164.87-742.2 193.22-1483.7 319.43-2252.68 297.14C1351.69 4138.16 645.453 3885.72 0 3508.41v79.86c599.496 466.23 1235.29 798.69 1995.69 897.87 718.96 93.8 1488.73-68.82 2165.73-309.87 1411.63-502.64 2867.23-1232.39 4403.77-1167.45 902.47 38.19 1701.51 315.53 2499.01 724.22v-74.86"></path>
        </g>
      </g>
    </svg>
    </div>
  );
}

export default Logo;
